<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="reviews_loading"
  >
    <Card content-class="c-table">
      <div class="header-container">
        <b-select-validation
          v-model="selectedLanguage"
          size="is-small"
          :label="$tc('language', 1)"
          hide-details
          style="min-width: 150px"
          @input="getReviews"
        >
          <template>
            <option v-for="opt in languages" :key="opt.iso" :value="opt.iso">
              {{ `${opt.label}` }}
            </option>
          </template>
        </b-select-validation>

        <b-field position="is-right">
          <p v-for="item in status" :key="item.value" class="control">
            <b-button
              size="is-small"
              :type="item.value == selected_status ? 'is-primary' : ''"
              @click="
                () => {
                  selected_status = item.value;
                  getReviews();
                }
              "
            >
              {{ item.text }}
            </b-button>
          </p>
        </b-field>
      </div>
      <hr class="my-0" />

      <b-table
        :data="items"
        :loading="reviews_loading"
        hoverable
        mobile-cards
        sortable
        backend-sorting
        default-sort="created_at.datetime"
        default-sort-direction="desc"
        striped
        :total="total"
        :row-class="
          (row, index) => {
            return {
              'bg-deleted': deleted_review == row.id,
              'bg-updated': updated_review == row.id,
            };
          }
        "
        @sort="onSort"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            :field="head.value"
            :label="head.label"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'search-product'">
                <SearchProduct
                  :disabled="reviews_loading"
                  label=""
                  type="product"
                  :sites-ids="allSites"
                  size="is-small"
                  rounded
                  hide-details
                  @selectItem="setProductFilter"
                />
              </template>
              <template v-else-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  :max-date="new Date()"
                  @input="getReviews()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  hide-details
                  @change="getReviews()"
                >
                  <template>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id]"
                      :value="opt[head.filter_id]"
                    >
                      {{ opt[head.filter_text] }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  hide-details
                  @change="getReviews()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getReviews"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <!-- site -->
            <template v-if="head.value == 'site_id'" v-slot="props">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site_id + '.svg'"
              />
            </template>

            <!-- id -->
            <template v-else-if="head.value === 'id'" v-slot="props">
              <span class="is-flex is-flex-wrap-nowrap">
                <span
                  v-if="selected_status != 'active'"
                  class="is-clickable mr-1"
                  @click="validate(props.row)"
                >
                  <b-icon icon="check" size="is-small" type="is-success" />
                </span>
                <a href="javascript:;" @click="editReview(props.row)">{{
                  props.row.id
                }}</a>
              </span>
            </template>

            <!-- user -->
            <template
              v-else-if="head.value === 'user.signature'"
              v-slot="props"
            >
              <router-link :to="`/users/${props.row.user.id}`">
                {{ props.row.user.signature }}
              </router-link>
            </template>

            <!-- product -->
            <template v-else-if="head.value === 'product.name'" v-slot="props">
              {{ props.row.product.name }}
            </template>

            <!-- rating -->
            <template v-else-if="head.value === 'rating'" v-slot="props">
              {{ props.row.rating }}
            </template>

            <!-- review -->
            <template v-else-if="head.value === 'review'" v-slot="props">
              <span>
                <b-icon
                  v-if="!!props.row.reply"
                  icon="reply"
                  custom-size="is-small"
                />
                {{ props.row.review }}
              </span>
            </template>

            <!-- created at -->
            <template
              v-else-if="head.value === 'created_at.datetime'"
              v-slot="props"
            >
              <span>
                {{ props.row.created_at.formatted }}
                <span class="has-text-grey-light">
                  {{ props.row.created_at.time }}
                </span>
              </span>
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="
                    $can('read', 'catalog.reviews') ||
                    $can('update', 'catalog.reviews')
                  "
                  aria-role="listitem"
                  @click="editReview(props.row)"
                >
                  <b-icon icon="eye" size="is-small" />
                  {{ $t("view") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    $can('update', 'catalog.reviews') &&
                    selected_status != 'active'
                  "
                  aria-role="listitem"
                  @click="validate(props.row)"
                >
                  <b-icon icon="check" size="is-small" />
                  {{ $t("validate") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    $can('delete', 'catalog.reviews') &&
                    selected_status != 'inactive'
                  "
                  aria-role="listitem"
                  @click="confirmDeleting(props.row)"
                >
                  <b-icon icon="delete" type="is-danger" size="is-small" />
                  {{ $t("delete") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else v-slot="props">
              <b-icon
                v-if="typeof props.row[head.value] === 'boolean'"
                :icon="props.row[head.value] ? 'check' : 'close'"
                :type="props.row[head.value] ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
              <template v-else>
                {{ showValue(props.row[head.value]) }}
              </template>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock slot="empty" icon="file-powerpoint-box" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import moment from "moment";
import debounce from "debounce";

import SearchProduct from "@/components/forms/elements/searchProduct.vue";

export default {
  components: {
    SearchProduct,
  },
  data() {
    return {
      sort: "created_at",
      sortOrder: "desc",
      updated_review: null,
      deleted_review: null,
      reviews_loading: true,
      currentPage: 1,
      perPage: 50,
      perPages: [20, 50, 200, 500],
      total: 0,
      selectedLanguage: null,
      items: [],
      allSites: [],
      languages: [],
      headers: [
        {
          label: this.$t("site"),
          value: "site_id",
          width: "80",
          sortable: true,
          searchable: true,
          filter_type: "select",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
        },
        {
          label: this.$t("id"),
          value: "id",
          width: "100",
          sortable: true,
          searchable: true,
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          label: this.$tc("customer", 1),
          value: "user.signature",
          width: "120",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
        },
        {
          label: this.$tc("product", 1),
          value: "product.name",
          width: "200",
          sortable: true,
          searchable: true,
          filter_type: "search-product",
          input: "",
          filter: "is",
        },
        {
          label: this.$tc("rating", 1),
          value: "rating",
          centered: true,
          width: "100",
          sortable: true,
          searchable: true,
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          label: this.$t("reviews"),
          value: "review",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
        },
        {
          label: this.$t("created_at"),
          value: "created_at.datetime",
          width: "200",
          sortable: true,
          searchable: true,
          filter_type: "date",
          input: null,
          filter: "is",
        },
        {
          label: this.$t("verified_purchase"),
          value: "verified",
          width: "80",
          centered: true,
          sortable: true,
          searchable: true,
          filter_type: "boolean",
          input: "",
          filter: "is",
        },
        {
          label: this.$t("actions"),
          value: "actions",
          centered: true,
          width: "80",
        },
      ],
      selected_status: "to_approve",
      status: [
        {
          text: this.$tc("to_process", 2),
          value: "to_approve",
        },
        {
          text: this.$tc("active", 2),
          value: "active",
        },
        {
          text: this.$tc("inactive", 2),
          value: "inactive",
        },
      ],
    };
  },
  watch: {
    perPage() {
      this.getReviews();
    },
    currentPage() {
      this.getReviews();
    },
  },
  created() {
    this.updateTitle(this.$i18n.t("reviews"));
    this.getReviews();
  },
  mounted() {
    this.$bus.$on("update-review", (review) => {
      let index = this.items.findIndex((r) => r.id == review.id);

      if (index != -1) {
        this.items.splice(index, 1, review);

        this.updated_review = review.id;
        setTimeout(() => {
          this.updated_review = null;
        }, 2000);
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("update-review");
  },
  methods: {
    onSort(field, order) {
      this.sort = field.split(".")[0];
      this.sortOrder = order;
      this.getReviews();
    },
    getReviews: debounce(function () {
      if (!this.$can("read", "catalog.reviews")) return;
      this.reviews_loading = true;
      let api_endoint = `reviews?status=${this.selected_status}&per_page=${this.perPage}&page=${this.currentPage}`;

      if (this.sort) {
        api_endoint += `&sorting=${this.sort}:${this.sortOrder}`;
      }

      if (this.selectedLanguage)
        api_endoint += "&language=" + this.selectedLanguage;

      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (value.input || typeof value.input == "boolean") {
          if (value.value == "id") this.hasOrderId = true;
          let obj = {
            id: value.value.split(".")[0],
            selected:
              value.value == "created_at.datetime"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        api_endoint += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.$axios
        .get(api_endoint)
        .then((res) => {
          this.items = res.data.list;
          this.total = res.data.pagination.total;

          let sites = res.data.filters.sites;
          let site_id_index = this.headers.findIndex(
            (h) => h.value == "site_id"
          );
          if (site_id_index != -1)
            this.headers[site_id_index].filter_values = sites;

          this.allSites = sites.reduce((arr, s) => [...arr, s.id], []);

          this.languages = res.data.filters.languages;
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.reviews_loading = false));
    }, 200),
    setProductFilter(product) {
      let index_product = this.headers.findIndex(
        (h) => h.value == "product.name"
      );
      if (index_product) this.headers[index_product].input = product.id;
      this.getReviews();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getReviews();
    },
    showValue(value) {
      return value !== undefined && value !== null ? value : "-";
    },
    validate(review) {
      if (!this.$can("update", "catalog.reviews")) return;
      this.$axios
        .patch("reviews/" + review.id + "/approve")
        .then(() => {
          this.updated_review = review.id;
          setTimeout(() => {
            this.items = this.items.filter((i) => i.id !== review.id);
            this.updated_review = null;
          }, 500);
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch((e) => this.clientError(e));
    },
    editReview(review) {
      if (!this.$can("update", "catalog.reviews")) return;
      this.$root.openDrawer("reviewForm", {
        review: review,
      });
    },
    confirmDeleting(review) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteReview(review),
      });
    },
    deleteReview(review) {
      if (!this.$can("delete", "catalog.reviews")) return;
      this.$axios
        .delete("reviews/" + review.id)
        .then(() => {
          this.deleted_review = review.id;
          setTimeout(() => {
            this.items = this.items.filter((i) => i.id !== review.id);
            this.deleted_review = null;
          }, 500);
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch((e) => this.clientError(e));
    },
  },
};
</script>
